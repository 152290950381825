import React from "react"
import { graphql, StaticQuery } from "gatsby"
import StoryCard from "../blog/storyCard"

function SuggestedStories(props) {
  var currentItem = 0
  return (
    <>
      <StaticQuery
        query={graphql`
          query landingSuggestedStories {
            SuperOps {
              posts(orderBy: date_DESC) {
                id
                title
                excerpt
                author {
                  id
                  name
                  slug
                  team
                }
                coverImage {
                  id
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 516 } }
                    }
                  )
                }
                tags
                readTime
                slug
                isBlog
              }
            }
          }
        `}
        render={data => (
            <div className="grid-container">
            {data.SuperOps.posts.map(post => {
              const {
                id,
                title,
                excerpt,
                author,
                coverImage,
                tags,
                readTime,
                slug,
                isBlog,
              } = post
              return (
                <>
                  {id !== props.postID && !tags.includes(props.tag) && currentItem < 3 ? (
                    <div className="grid-lg-3 grid-md-2 grid-sm-1">
                      <span className="dspnone">{currentItem++}</span>
                      <StoryCard
                        title={title}
                        excerpt={excerpt}
                        author={author}
                        coverImage={coverImage}
                        tags={tags}
                        readTime={readTime}
                        slug={slug}
                        contentEllipseLine="3"
                        headingEllipseLine="2"
                        cardSize="small"
                        contentMinHeight="365px"
                        isBlog={isBlog}
                        style={{ marginBottom: "48px" }}
                      />
                    </div>
                  ) : null}
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default SuggestedStories
