import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import TrackVisibility from "react-on-screen"
import ProgressBar from "react-scroll-progress-bar"
import ArrowRight from "../assets/images/right-arrow.svg"
import AuthorCard from "../components/blog/authorCard"
import SuggestedStories from "../components/blog/landingSuggestedStories"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import SVGIcon from "../components/common/SVGIcon"
import NavigationBlog from "../components/navigationBlog"
import SocialShare from "../components/social_share"
import CheckLinkDomain from "../components/utility/current_domain"
import "../styles/pages/blog-ks.scss"
import "../styles/pages/blog.scss"

function BlogPost({ pageContext }) {
  const { post } = pageContext
  const author = post.author
  const [ctaVisible, setCtaVisible] = useState(false)
  const [ctaState, setCtaState] = useState(true)

  return (
    <div className="blog">
      <StaticQuery
        query={graphql`
          query contentBlogSinglePost {
            SuperOps {
              navigationContents(where: { section: "Library" }) {
                section
                isParentOnly
                parentTitle
                parentDescription
                parentTagBg
                childTitle
                childSlug
                childDescription
                childIcon {
                  url
                }
                parentTagSecondaryBg
                parentSlug
              }

              pages(where: { title: "Blog" }) {
                title
                slug
                heroImage {
                  url
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
              ctaBoxes(where: { where: "BLOG CTA" }) {
                theme
                type
                heading {
                  html
                }
                description {
                  html
                }
                primaryButtonLink
                primaryButtonText
                isLinkVisible
                customBackgroundColor
                card {
                  image {
                    url
                    id
                  }
                  picture {
                    url
                    id
                  }
                  text
                }
              }
            }

            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.pages.map(page => {
              const twitterHandle = data.site.siteMetadata.twitterHandle
              const url = data.site.siteMetadata.url
              const shareTags = post.tags
              const shareSlug = post.slug
              const { navigationBlock, heroImage, slug } = page
              let promoImage, promoLink
              if (post.ebookPromotion?.ebookPromotionImage?.url) {
                promoImage = post.ebookPromotion.ebookPromotionImage?.url
                promoLink = post.ebookPromotion?.slug
              } else if (heroImage?.url) {
                promoImage = heroImage?.url
                promoLink = slug
              }

              return (
                <>
                  <Frame seo={post.seo} ogType="blog" footerClass={"p-0"}>
                    <NavigationBlog
                      links={navigationBlock}
                      background="#fff2f1"
                      libraryLinks={data.SuperOps.navigationContents}
                    />
                    <span className="pb">
                      <ProgressBar bgcolor="#FF0051" />
                    </span>
                    <div className="main-post">
                      <div className="hero">
                        <Container></Container>
                      </div>

                      <div className="content">
                        <Container className="Layout-container">
                          <Row className="blog-header">
                            <Col lg={2}>{/*  */}</Col>
                            <Col lg={10}>
                              <div className="tag-title">
                                {post.tags.map((tag, tagIndex) => {
                                  return (
                                    <>
                                      <a
                                        className="tdnone"
                                        href={`/blog/categories/${tag.toLowerCase()}`}
                                      >
                                        <span className="p14">
                                          {" "}
                                          <p> {tag.toLowerCase()} </p>{" "}
                                        </span>
                                        <p
                                          className="line"
                                          style={{
                                            display:
                                              tagIndex ===
                                                post.tags.length - 1 && "none",
                                          }}
                                        >
                                          {" "}
                                          |{" "}
                                        </p>
                                      </a>
                                    </>
                                  )
                                })}
                              </div>

                              <h1 className="topic"> {post.title} </h1>

                              <div className="author-details">
                                <Row>
                                  <Col lg={6}>
                                    <AuthorCard
                                      name={author.name}
                                      title={author.title}
                                      picture={author.picture}
                                      slug={author.slug}
                                      type={
                                        author.team !== "Yes" ? "link" : "team"
                                      }
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <div className="p12 a12">
                                      {!!post.credits
                                        ? parse(post.credits.html)
                                        : null}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <img
                            src={post.coverImage.url}
                            className="cover-img"
                            alt="cover"
                            height="100"
                            width="100"
                          />

                          <Row className="blog-content justify-content-end">
                            <Col lg={1}>{/*  */}</Col>
                            <Col lg={7}>
                              <div className="p24 excerpt">
                                <p> {post.excerpt} </p>
                              </div>

                              <div className="blog-single-main-content rich-text mt58">
                                {parse(post.content.html)}
                              </div>
                              {author.name !== "Team SuperOps" && (
                                <div className="author-card">
                                  <div className="d-flex author-header">
                                    <Link to={`/blog/author/${author.slug}`}>
                                      <div className="img-wrapper">
                                        <img
                                          src={author.authorCardImage?.url}
                                          alt="authorImg"
                                          className="w-100 h-100"
                                        />
                                      </div>
                                    </Link>
                                    <div className="altv1 d-flex justify-content-between w-100">
                                      <Link
                                        to={`/blog/author/${author.slug}`}
                                        className="no-hover-effect"
                                      >
                                        <div>
                                          <p className="tag">author</p>
                                          <div className="title-wrapper">
                                            <h5 className="title">
                                              {author.name}
                                            </h5>
                                          </div>
                                          <div className="">
                                            <p className="description">
                                              {author.title}
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                      <div className="social-links align-self-end">
                                        {author.twitterUrl && (
                                          <Link
                                            to={author.twitterUrl}
                                            target={
                                              CheckLinkDomain(author.twitterUrl)
                                                ? "_self"
                                                : "_blank"
                                            }
                                            rel="noreferrer"
                                          >
                                            <SVGIcon
                                              name="social/icontwitter"
                                              className="objcvr"
                                            />
                                          </Link>
                                        )}

                                        {author.linkedInUrl && (
                                          <Link
                                            to={author.linkedInUrl}
                                            target={
                                              CheckLinkDomain(
                                                author.linkedInUrl
                                              )
                                                ? "_self"
                                                : "_blank"
                                            }
                                            rel="noreferrer"
                                          >
                                            <SVGIcon
                                              name="social/iconlinkedin"
                                              className="objcvr"
                                            />
                                          </Link>
                                        )}

                                        {author.portfolioUrl && (
                                          <Link
                                            to={author.portfolioUrl}
                                            target={
                                              CheckLinkDomain(
                                                author.portfolioUrl
                                              )
                                                ? "_self"
                                                : "_blank"
                                            }
                                            rel="noreferrer"
                                          >
                                            <SVGIcon
                                              name="social/linkicon"
                                              className="objcvr"
                                            />
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p16 content biography-link">
                                    {!!author.biographyWithLinks ? (
                                      parse(author.biographyWithLinks.html)
                                    ) : (
                                      <p>{author.biography}</p>
                                    )}
                                  </div>
                                  <Link
                                    className={`d-flex footer-link`}
                                    to={`/blog/author/${author.slug}`}
                                  >
                                    read more
                                    <img
                                      src={ArrowRight}
                                      className=""
                                      alt="icon"
                                    />
                                  </Link>
                                </div>
                              )}
                            </Col>
                            <Col lg={3}>
                              <div className="share-cta">
                                <div className="share p16">
                                  <p> SHARE THIS ARTICLE: </p>
                                  <SocialShare
                                    socialConfig={{
                                      twitterHandle,
                                      config: {
                                        url: `${url}${shareSlug}`,
                                        title: post.title,
                                      },
                                    }}
                                    tags={shareTags}
                                    direction="vrt"
                                    page="blog"
                                    colorIcon={true}
                                  />
                                </div>
                                {promoImage && promoLink ? (
                                  <div
                                    className={`ebook-promo ${
                                      ctaVisible ? "card-visible" : ""
                                    } mt32`}
                                  >
                                    <Link
                                      to={promoLink}
                                      target={
                                        CheckLinkDomain(promoLink)
                                          ? "_self"
                                          : "_blank"
                                      }
                                    >
                                      <img
                                        src={promoImage}
                                        className="w-100 br10"
                                      />
                                    </Link>
                                  </div>
                                ) : (
                                  <div
                                    className={`blog-template-cta position-relative ${ctaState &&
                                      "open"} ${
                                      ctaVisible ? "card-visible" : ""
                                    }`}
                                  >
                                    {/* NOTE: Collapse btn */}
                                    {/* <button
                                      onClick={() => {
                                        setCtaState(!ctaState)
                                      }}
                                      className="toggle-btn position-absolute"
                                    >
                                      <SVGIcon
                                        name="accordion/down-arrow"
                                        className={`arrow-down ${
                                          ctaState ? "rotate-180" : ""
                                        }`}
                                      />
                                    </button> */}

                                    <div
                                      style={{
                                        display: ctaState ? "block" : "none",
                                      }}
                                    >
                                      <CTA
                                        data={data.SuperOps.ctaBoxes}
                                        newsletter
                                        buttonText="SUBSCRIBE TO OUR NEWSLETTER"
                                        blogCTA
                                        lgLeft={12}
                                        lgRight={12}
                                      />
                                    </div>

                                    {/* <div
                                      className="collapsed-box"
                                      onClick={() => {
                                        setCtaState(!ctaState)
                                      }}
                                      style={{
                                        display: ctaState ? "none" : "block",
                                      }}
                                    >
                                      <h5 className="fw-bold">
                                        Start FREE Trial
                                      </h5>
                                    </div> */}
                                  </div>
                                )}
                                <TrackVisibility once>
                                  {({ isVisible }) => {
                                    setCtaVisible(isVisible)
                                    return <></>
                                  }}
                                </TrackVisibility>
                              </div>
                            </Col>
                          </Row>

                          <section className="stories">
                            <span className="p16 section-title">
                              <p>SUGGESTED STORIES</p>
                            </span>

                            <SuggestedStories postID={post.id} />
                          </section>
                        </Container>
                      </div>
                    </div>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </div>
  )
}

export default BlogPost
